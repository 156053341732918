import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/LifeSkill2023/iisc-workshop/1.jpg';
import p2 from 'assests/Photos/LifeSkill2023/iisc-workshop/2.jpg';
import p3 from 'assests/Photos/LifeSkill2023/iisc-workshop/3.jpg';
import p4 from 'assests/Photos/LifeSkill2023/iisc-workshop/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2023 from 'views/LifeSkill2023/Sidebar2023';

const IiscWorkshop2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Current Global Climate issues
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 8  &  Date: 2 SEPTEMBER 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        A workshop on the topic Current Global Climate issues was conducted by Mr. Krishna S Bharadwaj, for students of class 8 on Saturday, 2nd September 2023. Mr. Bharadwaj is currently doing his graduation from the prestigious Indian Institute of Sciences. The workshop intended to make the students aware about the factors causing Climate change and Global warming. 
        <br></br>
        The resource person gave an insight about the dangers posed to ozone layer by CFC’s and depletion of fossil fuels. He also threw light on the biological solutions to these problems. The main highlight of the workshop was to create awareness among students about current Global Climate issues and unique solutions to these. He also encouraged the school students to be a part of this global movement.
        <br></br>
        The session proved to be an enriching and learning experience for all the students. They gained valuable insights for the making of a better tomorrow.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
“Climate change is the Everest of all problems, the thorniest challenge facing humankind - Lewis Gordon Pugh”

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2023 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default IiscWorkshop2023;